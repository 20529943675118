import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~3],
		"/code": [4],
		"/demo/recent": [~5],
		"/feedback": [6],
		"/go/[...code]": [~7],
		"/info/imprint": [8,[2]],
		"/info/privacy": [9,[2]],
		"/info/terms-conditions": [10,[2]],
		"/k/[...code]": [~11],
		"/ride/[rideId]": [~12],
		"/share/[...files]": [~13],
		"/track/[track]/find": [14],
		"/track/[track]/register": [~15],
		"/track/[track]/rides": [~16],
		"/transaction/[key]": [~17],
		"/transaction/[key]/checkout": [~18],
		"/transaction/[key]/download": [~19]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';