import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { dev } from '$app/environment';

if (!dev) {
	Sentry.init({
		dsn: 'https://18756ef9094b4683a193e5d182dd5012@o4503974940835840.ingest.sentry.io/4505435751251968',
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [new Replay()],
		/* beforeSendTransaction: (transaction, hint) => {
			console.log('transaction', transaction, hint);
			return transaction;
		}, */
		beforeSend: (event, hint) => {
			if (event.exception) {
				Sentry.showReportDialog({
					eventId: event.event_id,
					user: {
						email: 'feedback@coaster.video',
					},
					labelEmail: 'Email (you can leave this)',
				});
			}
			return event;
		},
	});
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
